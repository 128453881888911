import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { Box, Text, Button, Flex } from "@singita/components"
import FormBuilder from "./FormBuilder"
import { client } from "../../apollo/availability-client"
import { useQuery } from "@apollo/client"
import { useSubmitForm } from "./hooks/useSubmitForm"
import { FormConfirmation } from "./components/FormConfirmation"
import FormTitle from "./components/FormTitle"
import FormLoading from "./components/FormLoading"
import { FORM_QUERY } from "./graphql/queries"
import { validate, normalizeFields } from "./utils/helpers"
import { FORM_STATUS } from "./common/constants"
import { uploadToS3 } from "./services/s3"
import { fileTypesMap } from "./utils/helpers"
import { getIpAddress } from "./services/ipAddress"
import { Translation } from "react-i18next"

const DEFAULT_FORM_VALUES = { newsletter: false }

const FormWrapper = ({
  successMessage,
  styles,
  formId,
  zapierUrl,
  events = [],
  defaultValues = null,
  mergeFields = {},
  ...rest
}) => {
  const [form, setForm] = useState({ ...defaultValues, ...DEFAULT_FORM_VALUES })
  const [errors, setErrors] = useState({})
  const formRef = useRef()
  const confirmRef = useRef()
  const [submitForm, apiLoading, status, changeStatus] = useSubmitForm(formId)
  const [uploadError, setUploadError] = useState(false)
  const { loading, error, data } = useQuery(FORM_QUERY, {
    variables: {
      id: formId,
    },
    client,
  })
  const [submitLoader, setSubmitLoader] = useState(false)
  const [hideForm, setHideForm] = useState(false)

  useEffect(() => {
    if (status === FORM_STATUS.SUCCESS) {
      resetForm()
      setHideForm(true)
      confirmRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }

    if (status === FORM_STATUS.FAILED) {
      setHideForm(true)
      setSubmitLoader(false)
      confirmRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [status, error])

  const handleSubmit = async (e, fields) => {
    e.preventDefault()
    if (form.a_password) {
      return
    }
    setSubmitLoader(true)
    changeStatus(FORM_STATUS.PENDING)

    const errors = validate(fields, form)

    if (Object.keys(errors).length || uploadError) {
      setSubmitLoader(false)
      setErrors(errors)
      return
    } else {
      const promises = []
      const files = []

      Object.keys(form).forEach(field => {
        if (fileTypesMap.includes(form[field].type)) {
          promises.push(uploadToS3(form[field]))
        }
      })

      if (promises.length) {
        await Promise.all(promises)
          .then(values => {
            files.push(...values)
          })
          .catch(err => {
            console.log("upload error", err)
            setUploadError(true)
          })
      }

      const ip = await getIpAddress()
      const url = typeof window !== "undefined" ? window.location.href : ""

      submitForm({
        variables: {
          input: {
            form: formId,
            fields: {
              ...normalizeFields(form),
              files: files,
              path: url,
              ip_address: ip,
              zapierUrl: zapierUrl,
            },
            extra: { ...rest },
          },
        },
      })
    }
  }

  const resetForm = () => {
    setSubmitLoader(false)
    setErrors({})
    setForm(DEFAULT_FORM_VALUES)
    formRef.current.reset()
  }

  const handleInputChange = (event, inputProps) => {
    if (inputProps) {
      setForm({
        ...form,
        [inputProps.name]: inputProps.value,
      })
    } else {
      const target = event.target
      const value = target.type === "checkbox" ? target.checked : target.value
      const name = target.name

      setForm({
        ...form,
        [name]: name === "email" ? value.toLowerCase() : value,
      })
    }
  }

  if (loading && !data) {
    return <FormLoading />
  }

  if (!data.viewForm) {
    return `No form found for formId: ${formId}`
  }

  if (error) {
    return (
      <FormConfirmation
        response={FORM_STATUS.FAILED}
        message={"Error fetching form."}
      />
    )
  }

  const { buttonText = "", fields = [] } = data.viewForm

  const merged = [...fields]
  if (mergeFields) {
    Object.keys(mergeFields).map(f => {
      const index = merged.findIndex(m => m.fieldId === f)
      merged[index] = { ...merged[index], ...mergeFields[f] }
    })
  }

  const isLoading = submitLoader || apiLoading

  return (
    <>
      <Box ref={confirmRef}>
        <FormConfirmation
          response={status}
          message={successMessage}
          uploadError={uploadError}
          reference={confirmRef}
        >
          <Button
            onClick={() => {
              changeStatus(FORM_STATUS.PENDING)
              setHideForm(false)
            }}
          >
            <Translation>{t => t("form.show-form")}</Translation>
          </Button>
        </FormConfirmation>
      </Box>
      <Box mb={[2]} hidden={hideForm}>
        <FormBuilder
          reference={formRef}
          fields={merged}
          onSubmit={e => handleSubmit(e, merged)}
          onChange={handleInputChange}
          errors={errors}
          formStyles={styles}
          buttonText={
            buttonText || <Translation>{t => t("form.button")}</Translation>
          }
          renderTitle={() => <FormTitle title={rest.title} />}
          form={form}
          setForm={setForm}
          isLoading={isLoading}
        />
        {Object.keys(errors).length ? (
          <Flex alignItems="center" justifyContent="center">
            <Box mt={[1]}>
              <Text color="statusError" size="small">
                <Translation>{t => t("form.required-fields")}</Translation>
              </Text>
            </Box>
          </Flex>
        ) : null}
      </Box>
    </>
  )
}

export default FormWrapper

export const formFields = graphql`
  fragment FormFields on ContentfulForm {
    contentful_id
    formId
    successMessage
    title
    desc: description
    zapierUrl
    node_locale
    events {
      type
      category
      action
    }
    styles {
      flexDirection
      alignItems
      justifyContent
      mb
      flexWrap
    }
  }
`
