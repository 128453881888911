import { useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_REGIONS_AND_LODGES } from '../graphql/queries';
import { availabilityReducer, initialValue as initialVal } from './reducer';
import { client } from '../../../apollo/availability-client';
import { ACTIONS } from './constants';

const useAvailability = (codes, isOpen, initialValue = initialVal) => {
  const [state, dispatch] = useReducer(availabilityReducer, initialValue);

  const { loading, data, error } = useQuery(QUERY_ALL_REGIONS_AND_LODGES, {
    client,
  });

  /**
   * Need to do some work to prepopulate the form and summary if we are
   * looking at a lodge or region.
   * */
  useEffect(() => {
    const regions = [];
    const lodges = [];

    if (codes && codes.region) {
      let name;
      const regions = codes.region.split(',');

      if (data && data.findRegions) {
        regions.forEach((region) => {
          name = data.findRegions.find(({ code }) => code === region).name;

          if (name) {
            regions.push(region);
          }
        });
      }
    }

    if (codes && codes.lodge) {
      let name;
      if (data && data.findRegions) {
        name = data.findRegions
          .find(({ code }) => code === codes.region)
          .lodges.find(({ code }) => code === codes.lodge).name;

        if (name) {
          lodges.push(codes.lodge);
        }
      }
    }

    dispatch({
      type: ACTIONS.SET_FORM,
      payload: { regions, lodges },
    });
  }, [data, codes, isOpen]);

  return { state, dispatch, data, loading, error };
};

export default useAvailability;
