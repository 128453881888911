import React from 'react';
import { get } from 'lodash';
import { BUTTON_TYPES, CONTENT_TYPES } from './constants';
import { Button, Popover } from '@singita/components';
import Link from '../components/ConditionalLink';
import LinkWrapper from '../components/Link';

export const homeLink = {
  to: '/',
  as: Link,
  id: 'Singita_Home',
};

const BUTTON_MAP = {
  [BUTTON_TYPES.PRIMARY]: 'primary',
  [BUTTON_TYPES.OUTLINE]: 'outline',
  [BUTTON_TYPES.DOWNLOAD]: 'text',
  [BUTTON_TYPES.TEXT]: 'text',
};

export function mapperWithFunction(data, map) {
  const newData = {};

  Object.keys(map).forEach((key) => {
    const property = map[key];

    if (typeof property === 'function') {
      newData[key] = property(data);
    } else {
      newData[key] = get(data, property, null);
    }
  });

  return newData;
}

const getLabel = (link) => {
  const { label, structuredData, headline, name } = link;

  if (label) return label;

  if (structuredData) {
    return structuredData.menuLabel || structuredData.headline;
  }

  return headline || name;
};

export function getLinkProps(link, toggleModal) {
  const {
    __typename,
    slug,
    type,
    sx,
    variant,
    icon,
    iconPosition,
    size,
    colorScheme = 'brandBrown',
    fontWeight,
    isDownload = false,
  } = link;

  const buttonProps = {
    variant,
    sx,
    colorScheme,
    icon,
    iconPosition,
    size,
    fontWeight,
  };

  switch (__typename) {
    case CONTENT_TYPES.PAGE: {
      return {
        linkProps: {
          to: `/${getSlugSuffix(slug)}`,
          as: Link,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      };
    }
    case CONTENT_TYPES.PROMOTION:
    case CONTENT_TYPES.PROJECT:
    case CONTENT_TYPES.LODGE: {
      return {
        linkProps: {
          to: getFullSlug(__typename, getSlugSuffix(slug)),
          as: Link,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      };
    }
    case CONTENT_TYPES.ARTICLE: {
      return {
        linkProps: {
          to: generateBlogSlug(slug, link.year, link.month),
          as: Link,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      };
    }
    case CONTENT_TYPES.LINKS: {
      const linkProps = {};
      let children;

      if (Object.values(BUTTON_TYPES).includes(type)) {
        if (link.external || link.to) {
          linkProps.as = 'a';
          linkProps.href = getSlugSuffix(link.to);
          linkProps.target = '_blank';
        } else {
          if (link.destination) {
            return getLinkProps(
              { ...link, ...link.destination, variant: BUTTON_MAP[link.type] },
              toggleModal,
            );
          }
          linkProps.as = Link;
          linkProps.to = getSlugSuffix(link.to);
        }
        children = (
          <Button {...buttonProps} variant={BUTTON_MAP[link.type]}>
            {getLabel(link)}
          </Button>
        );
      }
      return { linkProps, children };
    }
    case CONTENT_TYPES.MENU: {
      return {
        children: (
          <Button
            colorScheme={colorScheme}
            variant="primary"
            popoverPosition="top"
            renderPopover={(colorScheme) => (
              <Popover>
                {link.menuItems &&
                  link.menuItems.length > 0 &&
                  link.menuItems.map((menuItem) => (
                    <LinkWrapper
                      {...menuItem}
                      sx={{ width: '100%' }}
                      variant="simple"
                      colorScheme={colorScheme}
                    />
                  ))}
              </Popover>
            )}
          >
            {getLabel(link)}
          </Button>
        ),
      };
    }
    case CONTENT_TYPES.PERSON: {
      return {
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
        linkProps: {
          onClick: () =>
            toggleModal
              ? toggleModal({
                  headline: link.name,
                  content: link.description,
                  image: link.image,
                })
              : null,
        },
      };
    }
    case CONTENT_TYPES.CONTENT: {
      return {
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
        linkProps: {
          onClick: () => (toggleModal ? toggleModal(link) : null),
        },
      };
    }
    default: {
      const linkProps = {};
      if (link.external || link.to) {
        linkProps.as = 'a';
        linkProps.href = getSlugSuffix(link.to);
        linkProps.target = '_blank';
        linkProps.download = isDownload;
      } else {
        linkProps.as = Link;
        linkProps.to = getSlugSuffix(link.to);
      }
      return {
        linkProps,
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      };
    }
  }
}

export function generateBlogSlug(slug, year, month) {
  return `/${year}/${month}/${getSlugSuffix(slug)}`;
}

const prefixMap = {
  [CONTENT_TYPES.PAGES]: '/',
  [CONTENT_TYPES.PROJECT]: '/conservation/projects/',
  [CONTENT_TYPES.LODGE]: '/lodge/',
  [CONTENT_TYPES.PROMOTION]: '/promotion/',
};

export function getFullSlug(type, slug) {
  const prefix = prefixMap[type] || '/';
  return `${prefix}${getSlugSuffix(slug)}`;
}

export function getSlugSuffix(slug, suffix = '/') {
  if (!slug) return null;
  let url = slug;
  if (url && !url.endsWith(suffix)) url += suffix;
  return url;
}

export function getIdForTag(tagName, tags) {
  return Object.keys(tags).find((key) => tags[key] === tagName);
}

export const showByTag = (array, tag) => {
  return array.filter((a) => {
    if (!a.tag || a.tag.length === 0) return true;
    return a.tag.includes(tag);
  });
};

export const hideByTag = (array, tag) => {
  return array.filter((a) => !a.tag || !a.tag.includes(tag));
};

export function getIdFromVimeoURL(url) {
  var m = url.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
  return m ? m[2] || m[1] : null;
}

export async function getVimeoMeta(id) {
  if (!id) return null;
  const meta = await fetch(
    `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`,
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return {
    thumbnailUrl: meta.thumbnail_url,
    description: meta.description,
    title: meta.title,
  };
}
